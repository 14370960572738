import 'chart.js';
import { CountUp } from 'countup.js';
import { nodeName } from 'jquery';
import { throttle } from 'throttle-debounce';

// Counters

let options = {
  duration: 13,
  separator: '.',
  decimal: ',',
};

var dossiers = new CountUp(
  document.getElementById('dossiers'),
  154749,
  options
);

options.duration = 8;

var regelingen = new CountUp(
  document.getElementById('regelingen'),
  80962,
  options
);

options.duration = 10;

var calls = new CountUp(document.getElementById('calls'), 128808, options);

// Bar Charts
Chart.pluginService.register({
  beforeRender: function (chart) {
    if (chart.config.options.showAllTooltips) {
      chart.pluginTooltips = [];
      chart.config.data.datasets.forEach(function (dataset, i) {
        chart.getDatasetMeta(i).data.forEach(function (sector, j) {
          chart.pluginTooltips.push(
            new Chart.Tooltip(
              {
                _chart: chart.chart,
                _chartInstance: chart,
                _data: chart.data,
                _options: chart.options.tooltips,
                _active: [sector],
              },
              chart
            )
          );
        });
      }); // turn off normal tooltips
      chart.options.tooltips.enabled = false;
    }
  },
  afterDraw: function (chart, easing) {
    if (chart.config.options.showAllTooltips) {
      // we don't want the permanent tooltips to animate, so don't do anything till the animation runs atleast once
      if (!chart.allTooltipsOnce) {
        if (easing !== 1) return;
        chart.allTooltipsOnce = true;
      }
      chart.options.tooltips.enabled = true;
      Chart.helpers.each(chart.pluginTooltips, function (tooltip) {
        tooltip.initialize();
        tooltip.update(); // we don't actually need this since we are not animating tooltips
        tooltip.pivot();
        tooltip.transition(easing).draw();
      });
      chart.options.tooltips.enabled = false;
    }
  },
});

let barchart_options = {
  showAllTooltips: true,
  title: {
    display: false,
  },
  legend: {
    display: false,
  },
  scales: {
    xAxes: [
      {
        categoryPercentage: 1,
        display: true,
        barPercentage: 0.4,
        gridLines: {
          display: false,
        },
      },
    ],

    yAxes: [
      {
        ticks: {
          beginAtZero: true,
          max: 200,
          stepSize: 50,
        },
      },
    ],
  },
  tooltips: {
    cornerRadius: 1,
    yAlign: 'bottom',
    xAlign: 'center',
    titleAlign: 'center',
    backgroundColor: '#26264E',
    titleFontFamily: 'Museo Sans',
    titleFontSize: 12,
    titleMarginBottom: 0,
    callbacks: {
      title: function (tooltipItems, data) {
        // return tooltipItems[0].yLabel;
        return tooltipItems[0].value.replace('.', ',');
      },

      label: function (tooltipItem, data) {
        return '';
      },
    },
  },
  animation: {
    duration: 2000,
    easing: 'easeOutQuad',
  },
};

var bar_ctx = document.getElementById('chart-geincasseerd').getContext('2d');

var background_1 = bar_ctx.createLinearGradient(0, 0, 0, 200);
background_1.addColorStop(0, 'rgba(246, 171, 191, 0.9)');
background_1.addColorStop(1, 'rgba(107, 30, 116, 0.9)');

let chartklachten = false;
let chartexploten = false;
let chartgriffierechten = false;
let chartdagvaardiging = false;
let chartbezoekers = false;
let chartgeincasseerd = false;

window.addEventListener(
  'scroll',
  throttle(500, () => {
    if (
      isInViewport(
        document.getElementById('chart-geincasseerd'),
        window.innerHeight / 4
      )
    ) {
      if (!chartgeincasseerd) {
        new Chart(document.getElementById('chart-geincasseerd'), {
          type: 'bar',
          data: {
            labels: ['2021', '2020'],
            datasets: [
              {
                data: [143.5, 139.6],
                backgroundColor: [background_1, background_1],
              },
            ],
          },
          options: barchart_options,
        });
        chartgeincasseerd = true;
      }
    }

    if (
      isInViewport(
        document.getElementById('chart-dagvaardiging'),
        window.innerHeight / 4
      )
    ) {
      if (!chartdagvaardiging) {
        barchart_options.scales.yAxes[0].ticks.max = 35;
        barchart_options.scales.yAxes[0].ticks.stepSize = 5;
        barchart_options.scales.yAxes[0].ticks.min = 15;
        barchart_options.scales.yAxes[0].ticks.beginAtZero = false;

        new Chart(document.getElementById('chart-dagvaardiging'), {
          type: 'bar',
          data: {
            labels: ['2021', '2020'],
            datasets: [
              {
                data: [17.858, 19.984],
                backgroundColor: [background_1, background_1],
              },
            ],
          },
          options: barchart_options,
        });
        chartdagvaardiging = true;
      }
    }

    if (
      isInViewport(
        document.getElementById('chart-griffierechten'),
        window.innerHeight / 4
      )
    ) {
      if (!chartgriffierechten) {
        barchart_options.scales.yAxes[0].ticks.max = 5;
        barchart_options.scales.yAxes[0].ticks.stepSize = 1;
        barchart_options.scales.yAxes[0].ticks.beginAtZero = false;
        barchart_options.scales.yAxes[0].ticks.min = 1;

        new Chart(document.getElementById('chart-griffierechten'), {
          type: 'bar',
          data: {
            labels: ['2021', '2020'],
            datasets: [
              {
                data: [3.2, 3.8],
                backgroundColor: [background_1, background_1],
              },
            ],
          },
          options: barchart_options,
        });
        chartgriffierechten = true;
      }
    }

    if (
      isInViewport(
        document.getElementById('chart-exploten'),
        window.innerHeight / 4
      )
    ) {
      if (!chartexploten) {
        barchart_options.scales.yAxes[0].ticks.max = 300;
        barchart_options.scales.yAxes[0].ticks.stepSize = 50;
        barchart_options.scales.yAxes[0].ticks.beginAtZero = false;
        barchart_options.scales.yAxes[0].ticks.min = 50;

        new Chart(document.getElementById('chart-exploten'), {
          type: 'bar',
          data: {
            labels: ['2021', '2020'],
            datasets: [
              {
                data: [175.45, 181.47],
                backgroundColor: [background_1, background_1],
              },
            ],
          },
          options: barchart_options,
        });
        chartexploten = true;
      }
    }

    if (
      isInViewport(
        document.getElementById('chart-klachten'),
        window.innerHeight / 4
      )
    ) {
      if (!chartklachten) {
        barchart_options.scales.yAxes[0].ticks.max = 1000;
        barchart_options.scales.yAxes[0].ticks.stepSize = 200;
        barchart_options.scales.yAxes[0].ticks.beginAtZero = true;
        barchart_options.scales.yAxes[0].ticks.min = 0;

        new Chart(document.getElementById('chart-klachten'), {
          type: 'bar',
          data: {
            labels: ['2021', '2020'],
            datasets: [
              {
                data: [397, 572],
                backgroundColor: [background_1, background_1],
              },
            ],
          },
          options: barchart_options,
        });
        chartklachten = true;
      }
    }

    if (
      isInViewport(
        document.getElementById('chart-bezoekers'),
        window.innerHeight / 4
      )
    ) {
      if (!chartbezoekers) {
        barchart_options.scales.yAxes[0].ticks.max = 400;
        barchart_options.scales.yAxes[0].ticks.stepSize = 50;
        barchart_options.scales.yAxes[0].ticks.beginAtZero = false;
        barchart_options.scales.yAxes[0].ticks.min = 200;

        new Chart(document.getElementById('chart-bezoekers'), {
          type: 'bar',
          data: {
            labels: ['2021', '2020'],
            datasets: [
              {
                data: [339.8, 347.3],
                backgroundColor: [background_1, background_1],
              },
            ],
          },
          options: barchart_options,
        });
        chartbezoekers = true;
      }
    }

    if (isInViewport(document.getElementById('dossiers'))) {
      dossiers.start();
    }

    if (isInViewport(document.getElementById('regelingen'))) {
      regelingen.start();
    }

    if (isInViewport(document.getElementById('calls'))) {
      calls.start();
    }
  })
);

function isInViewport(element, delay = 0) {
  const rect = element.getBoundingClientRect();

  return (
    rect.top + element.offsetHeight >= 0 &&
    rect.left >= 0 &&
    rect.bottom + delay <=
      (window.innerHeight || document.documentElement.clientHeight) +
        element.offsetHeight &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}
